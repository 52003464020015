import axios from "axios";
import React, { useEffect, useState } from "react";

const Home = () => {
  const [totalNews, setTotalNews] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_Local}/news`
        );

        if (Array.isArray(response.data)) {
          setTotalNews(response.data.length);
        } else {
          setError("Unexpected response format: expected an array");
        }
      } catch (error) {
        if (error.response) {
          setError(
            "Failed to fetch news: " +
              (error.response.data.message || error.message)
          );
        } else {
          setError("Failed to fetch news: " + error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mt-4">
      <div className="card rounded" style={{ width: "300px" }}>
        <div className="card-body">
          <h5 className="card-title">Total News</h5>
          <p className="card-text">{totalNews}</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
