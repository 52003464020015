import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; 

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import News from "../content/News";
import Home from "../Home/Home";

const Dashboard = () => {
  return (
    <Router>
      {/* <Topbar /> */}
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ flex: 1, padding: "20px" }}>
          <Switch>
            <Route path="/admin/dashboard" component={Home} />
            <Route path="/admin/news" component={News} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default Dashboard;
