import React from "react";
import Team from "../team/TeamTwo";
import SliderImage from "../component/slider/SliderImage";
import About from "../elements/About";
import BrandPersonality from "../elements/BrandPersonality";

import { LeafTitle } from "../component/common/LeafTitle";
import Header from "../component/header/Header";
import PageHelmet from "../component/common/Helmet";
import CounterTwo from "../elements/counters/CounterHome";
import CounterAbout from "../elements/counters/CounterAbout";
import CounterHome from "./../elements/counters/CounterHome";
import BrandTwo from "./../elements/BrandTwo";
import ccci from "../assets/images/ccci.png";
const AboutUs = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />
      <PageHelmet pageTitle="About Us" />
      <SliderImage title={"About Us"} />

      <CounterAbout />

      <About />

      {/* End About Area  */}

      <BrandPersonality />

      {/* Start Team Area  */}
      <div className="rn-team-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-style--3 text-center mb--10 mb_sm--0">
                <LeafTitle title={"Management Team"} />
              </div>
            </div>
          </div>

          <Team
            column="col-lg-3 col-md-6 col-sm-6 col-12"
            teamStyle=""
            item="4"
          />
        </div>
      </div>
      {/* End Team Area  */}

      {/* Our Brands Area  */}
      <div className="rn-team-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-style--3 text-center mb--10 mb_sm--0">
                <LeafTitle
                  title={"Membership, Accreditation & Certifications"}
                />
              </div>
            </div>
          </div>

          <div className="certificationsWrapper"  style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
            <img src={ccci} alt="" style={{maxWidth:"300px",marginBottom:"20px",marginTop:"40px"}} />
            <h3 style={{border:"2px solid green",padding:"10px"}}>ID : 0016508/2024-2025/2314</h3>
          </div>
        </div>
      </div>
      {/* End Our Brands Area  */}
    </>
  );
};

export default AboutUs;
