import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";

function ContactForm({ productName }) {
  const SubjectOptions = [
    { value: "General", label: "General" },
    { value: "Export", label: "Export" },
    { value: "Import", label: "Import" },
    { value: "Local Trading", label: "Local Trading" },
    { value: "Online Business", label: "Online Business" },
  ];

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    countryCode: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({ ...prevData, subject: selectedOption.value }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const API_KEY = process.env.REACT_APP_BREVO_API;

    const data = {
      sender: { email: "shakib@farmvaly.com", name: formData.fullname },
      to: [{ email: "business@farmvaly.com", name: "Farmvaly" }],
      subject: formData.subject,
      htmlContent: `
        <html>
        <body>
          <div>
            <p><strong>Name: </strong>${formData.fullname}</p>
            <p><strong>Email: </strong>${formData.email}</p>
            <p><strong>Phone: </strong>${formData.countryCode} ${formData.phoneNumber}</p>
            <p><strong>Product: </strong>${productName}</p>
            <p><strong>Subject:</strong> ${formData.subject}</p>
            <p><strong>Message:</strong></p>
            <div>${formData.message}</div>
          </div>
        </body>
        </html>
      `,
    };

    try {
      const response = await axios.post(
        "https://api.brevo.com/v3/smtp/email",
        data,
        { headers: { "Content-Type": "application/json", "api-key": API_KEY } }
      );
      toast.success(
        "Your message has been successfully sent. We will contact you soon."
      );
    } catch (error) {
      toast.error("There was an error sending your message. Please try again.");
    } finally {
      setLoading(false);
      e.target.reset();
      setFormData({
        fullname: "",
        email: "",
        countryCode: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
    }
  };

  return (
    <div>
      <form onSubmit={sendEmail}>
        <div className="rn-form-group" style={{ marginBottom: "15px" }}>
          <input
            type="text"
            name="fullname"
            placeholder="Name *"
            required
            value={formData.fullname}
            onChange={handleInputChange}
          />
        </div>

        <div className="rn-form-group" style={{ marginBottom: "15px" }}>
          <input
            type="email"
            name="email"
            placeholder="Email *"
            required
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>

        <div
          className="rn-form-group"
          style={{
            marginBottom: "15px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <PhoneInput
            country={"bd"}
            value={formData.phoneNumber}
            onChange={(phone) =>
              setFormData((prevData) => ({
                ...prevData,
                phoneNumber: phone,
              }))
            }
        
            specialLabel={""}
            inputStyle={{
              height: "50px",
              width: "100%",
              paddingLeft: "48px",
              fontSize: "14px", 
            }}
            
          />
        </div>

        <div className="rn-form-group" style={{ marginBottom: "15px" }}>
          <Select
            defaultValue={formData.subject}
            onChange={handleSelectChange}
            options={SubjectOptions}
            placeholder="Subject"
            styles={{
              control: (base) => ({
                ...base,
                height: "50px",
                minHeight: "50px",
              }),
              menu: (base) => ({
                ...base,
                fontSize: "14px",
              }),
            }}
          />
        </div>

        <div className="rn-form-group" style={{ marginBottom: "15px" }}>
          <textarea
            name="message"
            placeholder="Message"
            required
            value={formData.message}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              minHeight: "100px",
            }}
          />
        </div>

        <div className="contactFormSubmitBtn">
          <button
            type="submit"
            className=""
            disabled={loading}
            style={{ padding: "10px 20px" }}
          >
            {loading ? "Sending..." : "Submit"}
          </button>
        </div>

        <ToastContainer />
      </form>
    </div>
  );
}

export default ContactForm;
