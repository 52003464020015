import React from 'react';
import '../scss/NewsPreviewCard.scss'
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";



const NewsPreviewCard = ({ newsId, imageSrc, title, onEdit, onDelete }) => {
  return (
    <div className="NewsPreviewCardWrapper">
      <div className="NewsPreviewCard">
        <div className="NewsPreviewCardLeft">
          <img
            src={`${process.env.REACT_APP_SERVER_Local}/uploads/${imageSrc}`}
            className="img-fluid rounded-start"
            alt="News Preview"
          />
        </div>
        <div className="NewsPreviewCardRight">
          <div className="titleButtons">
            <h5 className="card-title">{title}</h5>
            <div className='editDeleteButtonWrapper'>
              <button onClick={onEdit} className="btn btn-primary me-2"><FaEdit/></button>
              <button onClick={onDelete} className="btn btn-danger"><MdDeleteForever/></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPreviewCard;
