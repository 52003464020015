import React, { useState } from "react";

const ProductDetails = ({ product, openQuoteModal }) => {
  const [mainImage, setMainImage] = useState(product?.imageUrl[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleImageClick = (image, index) => {
    setMainImage(image);
    setActiveImageIndex(index);
  };

  return (
    <>
      <div className="product-details-wrapper">
        <div className="product-details-image-container">
          <img src={product.imageUrl} alt="Product" />
        </div>
        <div className="product-details-info">
          <div className="productNameDesc">
            <h2>{product.name}</h2>
            <p>{product.details}</p>
          </div>
          <div className="nutritions-Benefits">
            <div className="nutritions">
              <h3>Planting Duration</h3>
              {/* Rendering planting duration as a list if it's an array */}
              {Array.isArray(product.plantingDuration) ? (
                <ul>
                  {product.plantingDuration.map((duration, index) => (
                    <li key={index}>{duration}</li>
                  ))}
                </ul>
              ) : (
                <p>{product.plantingDuration}</p>
              )}
            </div>
            <div className="Benefits">
              <h3>Harvesting Duration</h3>
              {/* Rendering harvesting duration as a list if it's an array */}
              {Array.isArray(product.harvestingDuration) ? (
                <ul>
                  {product.harvestingDuration.map((duration, index) => (
                    <li key={index}>{duration}</li>
                  ))}
                </ul>
              ) : (
                <p>{product.harvestingDuration}</p>
              )}
            </div>
          </div>
          <div className="nutritions-Benefits">
            <div className="nutritions">
              <h3>Nutrition Profile</h3>
              {/* Rendering nutrition profile as a list */}
              {Array.isArray(product.nutritionProfile) ? (
                <ul>
                  {product.nutritionProfile.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              ) : (
                <p>{product.nutritionProfile}</p>
              )}
            </div>
            <div className="Benefits">
              <h3>Key Factors</h3>
              <p>{product.keyFactors}</p>
            </div>
          </div>

          <button
            onClick={() => openQuoteModal(product.name)}
            className="getaQuaoteModalButton"
          >
            Submit a quote
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
