import CCM from "../assets/images/products/CCM.png";
import JF from "../assets/images/products/JF.png";
import PF from "../assets/images/products/PF.png";
import BF from "../assets/images/products/BF.png";
import MV from "../assets/images/products/MV.png";
import FP from "../assets/images/products/FP.jpg";
import DRC from "../assets/images/products/DRC.png";
import PK from "../assets/images/products/PK.png";
import RCN from "../assets/images/products/RCN.png";
import CS from "../assets/images/products/CS.png";
import RS from "../assets/images/products/RS.png";
import BLSSS from "../assets/images/products/BLSSS.png";
import SMW from "../assets/images/products/SMW.png";
import p14 from "../assets/images/products/p14.png";
import BLSSD from "../assets/images/products/BLSSD.png";
import BSSD from "../assets/images/products/BSSD.png";
import SesameSeedOilSeed from "../assets/images/products/SesameSeedOilSeed.png";
import BO from "../assets/images/products/BeefOmasum/Omasumpicture.jpg";
import BP from "../assets/images/products/BeefPizzle/Bp1.webp";
import YSSD from "../assets/images/products/Sesame/YSSD.jpg";

const productsdata = [
  {
    id: 1,
    category: "special",
    name: "Beef Omasum",
    imageUrl: BO,
    details:
      "Beef Omasum, also known as the 'book stomach' or 'third stomach,' is one of the four compartments of a cow's stomach. It is a muscular organ that aids in digestion and absorbs nutrients from food. Omasum is rich in protein and essential vitamins, making it a valued ingredient in various cuisines.",
    plantingDuration: "N/A - Not applicable as it is an animal product.",
    harvestingDuration:
      "Beef omasum is typically harvested during the processing of cattle, which can vary based on the meat processing schedule.",
    nutritionProfile: [
      "High in protein",
      "Vitamin B12",
      "Iron",
      "Zinc",
      "Selenium",
      "Phosphorus",
    ],
    keyFactors:
      "Rich in nutrients and commonly used in traditional dishes; it is often prepared in stews or as a filling in various recipes.",
  },
  {
    id: 2,
    name: "Beef Pizzle",
    category: "special",
    imageUrl: BP,
    details:
      "Beef Pizzle, also known as bull pizzle, is the dried penis of a bull. It is often used as a dog chew or in traditional cuisines. It is rich in protein and is considered a delicacy in some cultures. Additionally, it is sometimes used in traditional medicine.",
    plantingDuration: "N/A - Not applicable as it is an animal product.",
    harvestingDuration:
      "Beef pizzle is typically harvested during the processing of cattle, which can vary based on the meat processing schedule.",
    nutritionProfile: [
      "High in protein",
      "Low in fat",
      "Vitamins B6 and B12",
      "Iron",
      "Selenium",
    ],
    keyFactors:
      "Often valued for its nutritional content and used as a natural dog treat; it is tough and long-lasting.",
  },
  {
    id: 3,
    category: "seed",
    name: "Sesame Seed (Oil Seed)",
    imageUrl: SesameSeedOilSeed,
    details:
      "Sesame seeds are a popular ingredient known for their nutty flavor and high oil content. They are used in cooking, baking, and oil extraction. Sesame seeds are rich in healthy fats, protein, and essential nutrients, making them a valuable addition to a variety of dishes and recipes.",
    plantingDuration: [
      "1: Mid-February to Mid-April",
      "2: Mid-August to Mid-September",
    ],
    harvestingDuration:
      "Typically ranges from 90 to 130 days after planting. However, the exact timing can vary depending on the specific variety, local weather conditions, and soil type.",
    nutritionProfile: [
      "Vitamin B1 (Thiamine)",
      "Vitamin E",
      "Minerals like Copper, Iron, Magnesium, Zinc",
      "Dietary fiber, Fats & Phytosterols",
    ],
    keyFactors: "Rich in essential fats and protein.",
  },
  {
    id: 4,
    category: "other",
    name: "Peanut Kernel",
    imageUrl: PK,
    details:
      "Peanut kernels are the edible seeds of the peanut plant. They are a popular snack and ingredient in many cuisines worldwide.",
    plantingDuration:
      "Planted from late February to early March in Bangladesh.",
    harvestingDuration: "Harvesting time depends on regional conditions.",
    nutritionProfile: [
      "Fiber",
      "Protein",
      "Vitamins: Vitamin E, B vitamins",
      "Minerals: Magnesium, Copper, Potassium",
    ],
    keyFactors: "Rich in protein and healthy fats.",
  },
  {
    id: 5,
    category: "seed",
    name: "Brown Sesame Seed (Double Skin) (Oil Seed)",
    imageUrl: BSSD,
    details:
      "Brown sesame seeds, particularly the double-skin variety, are a highly nutritious and versatile ingredient. They offer a unique flavor and are packed with essential nutrients and healthy fats.",
    plantingDuration:
      "Typically sown during the dry season in Bangladesh, which runs from November to February.",
    harvestingDuration: "They mature in 3-4 months after sowing.",
    nutritionProfile: [
      "Fiber",
      "Protein",
      "B Vitamins",
      "Vitamin E",
      "Minerals: Iron, Calcium, Magnesium, Copper, Zinc",
    ],
    keyFactors: "Rich in healthy fats and essential nutrients.",
  },
  {
    id: 6,
    category: "seed",
    name: "Black Sesame Seed (Single Skin) (Oil Seed)",
    imageUrl: BLSSS,
    details:
      "Black sesame seeds are highly nutritious and have a distinct nutty taste, packed with essential nutrients.",
    plantingDuration:
      "Typically sown during the dry season in Bangladesh, which runs from November to February.",
    harvestingDuration: "Mature within 3-4 months after sowing.",
    nutritionProfile: [
      "Fiber",
      "Protein",
      "B Vitamins",
      "Vitamin E",
      "Minerals: Iron, Calcium, Magnesium, Copper, Zinc",
    ],
    keyFactors: "Rich in antioxidants, calcium, and iron.",
  },
  {
    id: 7,
    category: "seed",
    name: "Small White (Oil Seed)",
    imageUrl: SMW,
    details:
      "Small white oil seeds are mild in flavor and high in oil content, commonly used in cooking and industrial applications.",
    plantingDuration: [
      "Rapeseed (Canola): November to December",
      "Mustard Seed: February to March",
      "Sesame Seed: Both winter and summer",
      "Poppy Seed: March to April",
    ],
    harvestingDuration: [
      "Rapeseed: April to May",
      "Mustard Seed: May to June",
      "Sesame Seed: 3-4 months after sowing",
      "Poppy Seed: May to June",
    ],
    nutritionProfile: [
      "Rapeseed: Omega-3 fatty acids, protein, fiber, Vitamin E",
      "Mustard Seed: Protein, fiber, calcium, iron",
      "Sesame Seed: Unsaturated fats, sesame lignans, copper, zinc",
      "Poppy Seed: Protein, fiber, iron, calcium",
    ],
    keyFactors: "Good source of essential fats, fiber, and protein.",
  },
  {
    id: 8,
    category: "seed",
    name: "Black Sesame Seed (Double Skin) (Oil Seed)",
    imageUrl: BLSSD,
    details:
      "Black sesame seeds are a flavorful and nutritious ingredient, rich in essential nutrients.",
    plantingDuration:
      "Sown during the dry season in Bangladesh, November to February.",
    harvestingDuration: "Mature in 3-4 months after sowing.",
    nutritionProfile: [
      "Fiber",
      "Protein",
      "B Vitamins",
      "Vitamin E",
      "Minerals: Iron, Calcium, Magnesium, Copper, Zinc",
    ],
    keyFactors: "Rich in antioxidants and healthy fats.",
  },
  {
    id: 9,
    category: "spices",
    name: "Rapeseed",
    imageUrl: RS,
    details:
      "Rapeseed, also known as canola, is a popular oilseed crop that produces high-quality edible oil. It's widely cultivated for its nutritional value and industrial applications.",
    plantingDuration:
      "Planted during the winter season, November to December in Bangladesh.",
    harvestingDuration: "Harvested in late spring, April to May.",
    nutritionProfile: [
      "Omega-3 fatty acids",
      "Protein",
      "Fiber",
      "Vitamin E",
      "Saturated fats, cholesterol",
    ],
    keyFactors: "Good source of healthy fats and essential nutrients.",
  },
  {
    id: 10,
    category: "spices",
    name: "Cumin Seed",
    imageUrl: CS,
    details:
      "Cumin is a popular spice widely used in various cuisines worldwide, including Indian, Middle Eastern, and Mexican. It is known for its distinct earthy-nutty flavor and aroma.",
    plantingDuration: "Sown during spring or early summer.",
    harvestingDuration: "Harvested in the fall.",
    nutritionProfile: [
      "Fiber",
      "Protein",
      "Minerals: Iron, Copper",
      "Compounds: Cuminaldehyde, Carvacrol",
    ],
    keyFactors: "Rich in antioxidants and essential oils.",
  },
  {
    id: 11,
    category: "spices",
    name: "Raw Cashew Nut",
    imageUrl: RCN,
    details:
      "Raw cashew nuts are a popular and nutritious snack that offer a delicious, slightly sweet flavor. They are rich in essential nutrients and healthy fats.",
    plantingDuration:
      "Cashew trees are typically planted in the rainy season, which varies by region, but often occurs between May and July.",
    harvestingDuration:
      "Cashew nuts are harvested from late November to early April, depending on the region and climate.",
    nutritionProfile: [
      "Protein",
      "Vitamins: Magnesium, Copper, Potassium",
      "Minerals and Fiber",
    ],
    caution:
      "Raw cashew nuts contain urushiol, which is removed during roasting or steaming. Always consume processed cashews.",
  },

  {
    id: 12,
    category: "spices",
    name: "Dry Red Chili (Spice)",
    imageUrl: DRC,
    details:
      "Dry red chili is a popular spice widely used in various cuisines worldwide, including Asian Subcontinent, Chinese, and Mexican. It's known for its intense heat and vibrant red color.",
    plantingDuration: "Planted during the dry season in warm climates.",
    harvestingDuration: "Harvested when pods are ripe and dry.",
    nutritionProfile: [
      "Capsaicin",
      "Fiber",
      "Vitamins A, C, B6",
      "Minerals: Potassium, Iron",
    ],
    keyFactors: "Known for their heat and antioxidant properties.",
  },
  {
    id: 13,
    category: "vegetable",
    name: "Fresh Potato (Vegetable)",
    imageUrl: FP,
    details:
      "Potatoes are one of the most widely consumed vegetables in the world, including in Bangladesh. They are known for their starchy texture, mild flavor, and versatility in cooking.",
    plantingDuration:
      "Planted from late November to early December in Bangladesh.",
    harvestingDuration: "Harvested in late February to early March.",
    nutritionProfile: [
      "Carbohydrates",
      "Vitamins: Vitamin C, Potassium",
      "Minerals: Iron, Copper",
    ],
    keyFactors: "Rich in carbs, providing energy and essential nutrients.",
  },
  {
    id: 14,
    category: "vegetable",
    name: "Mushroom (Vegetable)",
    imageUrl: MV,
    details:
      "Mushrooms are a unique type of fungus that are often considered vegetables due to their culinary uses. They are known for their earthy flavor, meaty texture, and nutritional value.",
    plantingDuration:
      "Mushroom spores or mycelium typically start fruiting within 2-4 weeks.",
    harvestingDuration: "Harvested 2-4 weeks after inoculation.",
    nutritionProfile: [
      "Vitamin B12",
      "Minerals: Selenium, Copper, Potassium",
      "Fiber, Antioxidants",
    ],
    keyFactors: "Low-calorie, nutrient-dense food.",
  },
  {
    id: 15,
    category: "fruits",
    name: "Banana (Fruit)",
    imageUrl: BF,
    details:
      "Bananas are one of the most popular and widely consumed fruits worldwide, including in Bangladesh.",
    plantingDuration: "Planted from June to September.",
    harvestingDuration: "Bananas bear fruit 9-12 months after planting.",
    nutritionProfile: [
      "Vitamin B6",
      "Mineral: Potassium",
      "Fiber, Antioxidants",
    ],
    keyFactors: "A high-energy fruit, rich in vitamins and minerals.",
  },
  {
    id: 16,
    category: "fruits",
    name: "Pineapple (Fruit)",
    imageUrl: PF,
    details:
      'Pineapples, known locally as "anarosh" in Bengali, are a popular and refreshing fruit in Bangladesh. They are widely cultivated and consumed due to their sweet, tangy flavor and nutritional benefits.',
    plantingDuration: "Planted during the monsoon season, June to August.",
    harvestingDuration: "Takes around 18 months to mature after planting.",
    nutritionProfile: [
      "Vitamin C",
      "Bromelain (enzymes)",
      "Fiber, Antioxidants",
    ],
    keyFactors: "Rich in vitamin C and digestive enzymes.",
  },
  {
    id: 17,
    category: "fruits",
    name: "Jackfruit (Fruit)",
    imageUrl: JF,
    details:
      'Jackfruit, known locally as "kanthal" in Bengali, is a popular and versatile fruit in Bangladesh. It\'s one of the largest fruits in the world and is prized for its unique flavor and nutritional value.',
    plantingDuration: "Monsoon season (June - September)",
    harvestingDuration:
      "3-4 years after planting; typically late spring (May) to early autumn.",
    nutritionProfile: [
      "Vitamins A, C, B6",
      "Potassium",
      "Copper",
      "Magnesium",
      "Manganese",
      "Fiber",
      "Antioxidants",
    ],
    keyFactors: "Unique flavor and nutritional value.",
  },
  {
    id: 18,
    category: "fruits",
    name: "CORN COB MEAL (COMMODITY ITEM)",
    imageUrl: CCM,
    details:
      "Corn Cob Meal is a versatile product used as a high-fiber feed supplement for livestock. It is made from the dried cobs of corn and is often used to enhance the diet of animals. Corn Cob Meal is known for its excellent fiber content and is commonly used in animal nutrition to promote healthy digestion and prevent digestive disorders.",
    plantingDuration:
      "Typically planted in early to mid-March in Bangladesh. However, this planting time can vary slightly depending on specific regional conditions and the desired harvest date.",
    harvestingDuration:
      "The harvesting duration for corn plants in Bangladesh typically ranges from late June to early July.",
    nutritionProfile: [
      "Vitamins A, C, B6, including thiamine, riboflavin, niacin, and folate",
      "Fiber",
      "Protein",
      "Copper",
      "Iron",
      "Magnesium",
      "Phosphorus",
      "Zinc",
    ],
    keyFactors:
      "Unsaturated fats, Omega-6 fatty acids, Sesame lignans, unique flavor, and nutritional value.",
  },

  {
    id: 19,
    category: "seed",
    name: "Yellow Sesame Seed (Double Skin) (Oil Seed)",
    imageUrl: YSSD,
    details:
      "Yellow sesame seeds have a slightly sweet, nutty flavor and are commonly used in cooking and baking. They are rich in healthy fats, protein, and essential nutrients. Yellow sesame seeds are often used in recipes for their flavor and nutritional benefits, including in salads, bread, and dressings.",
    plantingDuration:
      "Generally runs from November to February. This period provides optimal conditions for germination and growth, with sufficient sunlight and minimal rainfall.",
    harvestingDuration:
      "Harvesting is done when the seed pods are dry and brown, usually around 120-150 days after sowing.",
    nutritionProfile: [
      "Fiber",
      "Protein",
      "Vitamins B1, B2, B3, B9, including thiamine, riboflavin, niacin, and folate",
      "Copper",
      "Iron",
      "Magnesium",
      "Phosphorus",
      "Zinc",
    ],
    keyFactors: "Rich in healthy fats and nutrients.",
  },
];

export default productsdata;
