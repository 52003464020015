import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../scss/Sidebar.scss";
import logo from "../../assets/images/logo/logo.png";
import { toast } from "react-toastify";
import { MdDashboard } from "react-icons/md";
import { GiNewspaper } from "react-icons/gi";
import { RiArticleFill } from "react-icons/ri";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { ImMenu } from "react-icons/im";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const location = useLocation(); // Get the current location

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    history.push("/");
    toast.success("Successfully Logged Out");
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="mobile-menu-icon" onClick={toggleMenu}>
        <ImMenu />
      </div>

      <div className={`sidebar ${isOpen ? "open" : ""}`}>

        <div className="sidebar-logo">
          <img src={logo} alt="Elegant Logo" className="logo" />
        </div>
        <ul className="sidebar-menu">
          <li
            className={`menu-item ${
              location.pathname === "/admin/dashboard" ? "active" : ""
            }`}
          >
            <Link
              to="/admin/dashboard"
              className="menu-link"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <i className="icon home"></i>
              <MdDashboard style={{ marginRight: "2px" }} /> Dashboard
            </Link>
          </li>
          <li
            className={`menu-item ${
              location.pathname === "/admin/news" ? "active" : ""
            }`}
          >
            <Link
              to="/admin/news"
              className="menu-link"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <i className="icon pages"></i>
              <GiNewspaper style={{ marginRight: "2px" }} /> News
            </Link>
          </li>
          <li
            className={`menu-item ${
              location.pathname === "/admin/articles" ? "active" : ""
            }`}
          >
            <Link
              to="/admin/articles"
              className="menu-link"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <i className="icon applications"></i>
              <RiArticleFill style={{ marginRight: "2px" }} /> Articles
            </Link>
          </li>
        </ul>
        <div className="logout" onClick={handleLogout}>
          <i className="icon logout"></i>
          <RiLogoutBoxRLine style={{ marginRight: "6px" }} /> Log out
        </div>
      </div>
    </>
  );
};

export default Sidebar;
