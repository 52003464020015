import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";

const slideList = [
  {
    bgImage: "bg_image--15",
  },
  {
    bgImage: "bg_image--12",
  },
];

class SliderImage extends Component {
  render() {
    const { title = "", images = [] } = this.props;

    const slidesToRender = images.length > 0 ? images : slideList.map(slide => slide.bgImage);

    return (
      <div className="slider-activation">
        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
          {slidesToRender.map((image, index) => (
            <div
              className={`sliderimageaboutus slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${image}`}
              key={index}
              style={{ backgroundImage: `url(${image})` }} 
            >
              <div className="silderImageTitleWrapper">
                <h1 className="title theme-gradient">{title}</h1>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default SliderImage;
