import React from "react";
import onlinebusiness from "../assets/images/onlinebusiness.jpg";
import imageback from "../assets/images/imageback.jpg";
import { FaCheck } from "react-icons/fa";
import Header from "../component/header/Header";
import { LeafTitle } from "../component/common/LeafTitle";
import { motion } from "framer-motion";
import checkicon from "../assets/images/icons/check.png";
import SliderImage from "../component/slider/SliderImage";
import { GiChicken } from "react-icons/gi";
import { FaSeedling } from "react-icons/fa";
import { GiTropicalFish } from "react-icons/gi";
import { GiTomato } from "react-icons/gi";
import { GiOpenedFoodCan } from "react-icons/gi";
import mango from "../assets/images/mango.png"


const OnlineBusiness = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />

      <SliderImage title="Online Shop" />

      <div style={{ marginTop: "50px" }}></div>

      <LeafTitle title={"COMING SOON"} />

      <div className="lifestylebrandTextWrapper">
        <div className="container">
          <div className="upcomingProductCardWrapper">
            <div className="upcomingProductCard">
              <FaSeedling style={{ fontSize: "42px" }} />
              <h4>Seeds</h4>
            </div>
            <div className="upcomingProductCard">
              <GiTropicalFish style={{ fontSize: "42px" }} />
              <h4>Fish</h4>
            </div>
            <div className="upcomingProductCard">
              <GiTomato style={{ fontSize: "42px" }} />
              <h4>Vegetables</h4>
            </div>
            <div className="upcomingProductCard">
              <GiChicken style={{ fontSize: "42px" }} />
              <h4>Chicken</h4>
            </div>
            <div className="upcomingProductCard">
            <GiOpenedFoodCan style={{ fontSize: "42px" }} />
              <h4>Livestock Food</h4>
            </div>
            <div className="upcomingProductCard">
              <img src={mango} alt="" style={{maxWidth:"42px"}}/>
        
              <h4>Mango</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h2>Contact Us today</h2>
                <a className="rn-button-style--2" href="/contact">
                  <span>Contact Us</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineBusiness;
