import React from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaEnvelope,
} from "react-icons/fa";

import logo from "../../assets/images/logo/logowhite.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://m.facebook.com/FarmValy.BD" },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/farmvaly-bangladesh",
  },
  { Social: <FaEnvelope />, link: "business@farmvaly.com" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

const Footer = () => {
  return (
    <div className="main-footer-wrapper">
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/home-one">
                  <img src={logo} alt="Logo images" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 ">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <Link to="/contact">Contact</Link>
                <br />
                <Link to="/terms-&-conditions">Terms & Conditions</Link>
                <br />
                <Link to="/privacy-policy">Privacy Policy</Link>
                <br />
                <Link to="">
                  Membership, Accreditations & Certifications
                </Link>
                <p>
                  Copyright © {new Date().getFullYear()} Farmvaly. All Rights
                  Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
