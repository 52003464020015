import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {

  const token = localStorage.getItem('adminToken'); 
  const isAuthenticated = !!token; 
 

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" /> 
        )
      }
    />
  );
};

export default ProtectedRoute;
