import React from "react";
import { motion } from "framer-motion";
import img from "../assets/images/headshot.jpg";
import chairman from "../assets/images/team/chairman.jpg";
import ceo from "../assets/images/team/ceo.jpg";
import { FaFacebookF, FaLinkedinIn, FaEnvelope } from "react-icons/fa";

const TeamTwo = () => {
  const itemSlice = [
    {
      image: chairman,
      name: "MD Jasim Uddin Kabir",
      position: "Co-Founder & Chairman",
      socialNetwork: [
        { icon: <FaLinkedinIn />, url: "#" },
        { icon: <FaFacebookF />, url: "#" },
        { icon: <FaEnvelope />, url: "#" },
      ],
    },
    {
      image: ceo,
      name: "Shakib Arafat",
      position: "Founder & CEO",
      socialNetwork: [
        { icon: <FaLinkedinIn />, url: "#" },
        { icon: <FaFacebookF />, url: "#" },
        { icon: <FaEnvelope />, url: "#" },
      ],
    },
  ];

  return (
    <div className="team-wrapper">
      {itemSlice.map((value, i) => (
        <motion.div
          initial={{ y: 190, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ delay: i * 0.3, duration: 1.5 }}
          className="team-card"
          key={`${i}-${value.name}`}
        >
          <div className="card-inner">
            <div className="card-front">
              <div
                className="thumbnail"
                style={{
                  backgroundImage: `url(${value.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <h3 className="team-name">{value.name}</h3>
              <p className="designation">{value.position}</p>
            </div>
            <div className="card-back">
              <div className="content">
                <h4 className="title">{value.name}</h4>
                <p className="designation">{value.position}</p>
                <ul className="social-list">
                  {value.socialNetwork.map((social, index) => (
                    <li key={index} className="social-item">
                      <a href={social.url} className="social-link">
                        {social.icon}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default TeamTwo;
