import blog1 from "../assets/images/ataglance/n1.jpg";
import blog2 from "../assets/images/ataglance/n2.jpg";
import blog3 from "../assets/images/ataglance/c1.webp";
import blog4 from "../assets/images/ataglance/ed1.jpg";
import news1 from "../assets/images/news/29feb.png";
import news2 from "../assets/images/ataglance/a1.jpg";
import news3 from "../assets/images/ataglance/s2.jpg";
import news4 from "../assets/images/ataglance/h1.jpg";
import news5 from "../assets/images/ataglance/q2.jpg";

export const ArticleList = [
  {
    id: 1,
    images: blog1,
    title: "The Rise of Organic Farming",
    category: "Agriculture",
    details:
      "Explore how organic farming is transforming the agriculture industry, promoting sustainability, and enhancing food quality.",
  },
  {
    id: 2,
    images: blog2, // Replace 'blog2' with the actual image path or URL
    title: "Innovative Techniques in Modern Farming",
    category: "Agriculture",
    details:
      "Discover cutting-edge techniques and technologies that are revolutionizing farming practices and boosting productivity.",
  },
  {
    id: 3,
    images: blog3, // Replace 'blog3' with the actual image path or URL
    title: "Benefits of Farm-Fresh Organic Food",
    category: "Organic Food",
    details:
      "Learn about the numerous health benefits and environmental advantages of consuming farm-fresh organic food.",
  },
  {
    id: 4,
    images: blog4, // Replace 'blog4' with the actual image path or URL
    title: "Sustainable Agriculture: Practices and Benefits",
    category: "Agriculture",
    details:
      "An in-depth look at sustainable agricultural practices, their benefits for the environment, and their role in food security.",
  },
  {
    id: 5,
    images: blog1, // Reused image path for consistency
    title: "Top Organic Products for Your Farm",
    category: "Organic Food",
    details:
      "A guide to the best organic products available for farmers looking to enhance their organic farming operations.",
  },
  {
    id: 6,
    images: blog2, // Reused image path for consistency
    title: "Understanding Agri Products: From Farm to Market",
    category: "Agri Products",
    details:
      "Gain insights into the journey of agricultural products from the farm to the market, including processing and distribution.",
  },
];


export const NewsList = [
  {
    id: 1,
    images: news1,
    title:
      "Agricultural export potential high but meeting global standards a challenge",
    category: "Agriculture",
    details:
      "Considering global demand, Bangladesh has the opportunity to export more than 19 lakh tonnes of agricultural products by 2030. However, agricultural exporters believe the country faces significant challenges to achieve this.<br/><br/>In 2014, the EU imposed a ban on Bangladeshi betel leaf exports due to salmonella contamination. While salmonella-free betel leaf production resumed in 2021, it took 10 years to overcome this ban.<br/><br/>Currently, 49% of agricultural exports go to the Middle East, 32% to Europe, and 15% to Asia. However, Bangladesh's agricultural exports have declined significantly in the last decade, from $225.85 million to $74.93 million, with the quantity reducing from 1.28 lakh tonnes to 55,000 tonnes.<br/><br/>To capitalize on the international demand, Bangladesh must focus on Good Agricultural Practices (GAP), invest in cold storage, improve sea export capabilities, and extend the shelf life of products. Collaboration between the government and private sector is crucial for success.",
  },
  {
    id: 2,
    images: news2,
    title: "Bangladesh’s rice output crosses 4cr tonnes for the first time",
    category: "Agriculture",
    details:
      "For the first time, Bangladesh has produced more than 4 crore tonnes of rice in a fiscal year, thanks to higher yields from high-yielding and hybrid varieties.<br/><br/>Farmers harvested 4.06 crore tonnes of rice in FY 2023-24, a 4.1% increase year-on-year, with the largest growth coming from Aman paddy.<br/><br/>Experts credit the shift towards modern rice varieties and consistent growth over the past five years. No rice imports were required this year, though concerns remain about future harvests due to potential flooding and crop damage.<br/><br/>Wheat imports have also risen, lessening the pressure on rice, but future food security depends on managing fertiliser availability and flood impacts.",
  },
  {
    id: 3,
    images: news3,
    title: "Bangladesh exported $1.16bn agricultural products in FY22",
    category: "Agriculture",
    details:
      "In FY22, Bangladesh's agricultural exports crossed the $1 billion mark for the second consecutive year, reaching $1.16 billion, a 13.04% year-on-year increase.<br/><br/>The growth is part of the country's efforts to diversify its export basket, which has been heavily dependent on the garment industry. Exported agricultural goods include vegetables, fruits, spices, and tobacco, with major markets in the Middle East, Asia, and Africa.<br/><br/>Pran-RFL Group, the largest agro-processing exporter, plans to double its export earnings to $1 billion by 2025, contributing significantly to this growth.",
  },
  {
    id: 4,
    images: news4,
    title: "Breakthrough in Climate Change Research",
    category: "Science",
    details:
      "Scientists have made a significant breakthrough in climate change research, offering new hope for global environmental efforts.",
  },
  {
    id: 5,
    images: news5,
    title: "Advances in AI and Machine Learning",
    category: "Technology",
    details:
      "Explore the latest advancements in AI and machine learning and how they are shaping various industries around the world.",
  },
  {
    id: 6,
    images: news1,
    title: "Global Health: Tackling the Next Pandemic",
    category: "Health",
    details:
      "Public health experts discuss the steps needed to prepare for and combat the next global pandemic in the wake of COVID-19.",
  },
];
