import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import NewsPreviewCard from "../components/NewsPreviewCard";
import Modal from "react-modal"; // Import react-modal
import "./NewsModal.css";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// Set the app element for accessibility
Modal.setAppElement("#root");

const News = () => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [details, setDetails] = useState("");
  const [image, setImage] = useState(null);
  const [newsList, setNewsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalCategory, setModalCategory] = useState("");
  const [modalDetails, setModalDetails] = useState("");

  // Fetch news articles when the component mounts
  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_Local}/news`
      );
      if (!response.ok) throw new Error("Network response was not ok");

      const news = await response.json();
      setNewsList(news);
    } catch (err) {
      console.error("Error fetching the news:", err);
    }
  };

  const resetFormFields = () => {
    setTitle("");
    setCategory("");
    setDetails("");
    setImage(null);
    setSelectedNewsId(null);
    setModalTitle("");
    setModalCategory("");
    setModalDetails("");
  };

  const handleImageChange = (e) => setImage(e.target.files[0]);

  const handleAddNews = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("category", category);
    formData.append("details", details);
    if (image) formData.append("image", image);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_Local}/news`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success("News Added");
        resetFormFields();
        fetchNews();
      } else {
        toast.error("Failed to add the news.");
      }
    } catch (error) {
      console.error("Error submitting the news:", error.response ? error.response.data : error.message);
      toast.error("There was an error processing the request.");
    }
  };

  const handleUpdateNews = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", modalTitle);
    formData.append("category", modalCategory);
    formData.append("details", modalDetails);
    if (image) formData.append("image", image);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_Local}/news/${selectedNewsId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("News Updated");
        resetFormFields();
        fetchNews();
        setShowModal(false);
      } else {
        toast.error("Failed to update the news.");
      }
    } catch (error) {
      console.error("Error updating the news:", error);
      toast.error("There was an error processing the request.");
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_Local}/news/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      });
      toast.success("News Deleted");
      fetchNews();
    } catch (error) {
      console.error("Error deleting news:", error);
      toast.error("Failed to delete the news.");
    }
  };

  const handleEdit = (news) => {
    setModalTitle(news.title);
    setModalCategory(news.category);
    setModalDetails(news.details);
    setImage(null); // Keep the image state null unless the user selects a new image.
    setSelectedNewsId(news.id);
    setShowModal(true);
  };

  return (
    <div>
      <div className="container mt-2">
        <h2 className="text-center mb-4">Add News</h2>
        <form
          onSubmit={handleAddNews}
          className="bg-light p-4 rounded shadow"
        >
          <div className="form-group">
            <label htmlFor="image">Upload Image:</label>
            <input
              type="file"
              id="image"
              className="form-control-file"
              onChange={handleImageChange}
              accept="image/*"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category:</label>
            <input
              type="text"
              id="category"
              className="form-control"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="details">Details:</label>
            <ReactQuill
              value={details}
              onChange={setDetails}
              theme="snow"
              required
              style={{ minHeight: "300px" }}
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block">
            Submit
          </button>
        </form>
      </div>

      <div className="container mt-5">
        <h2 className="text-center mb-4">All News</h2>
        {newsList.length === 0 ? (
          <p>No news available.</p>
        ) : (
          <div>
            {newsList.map((news) => (
              <NewsPreviewCard
                key={news.id}
                newsId={news.id}
                imageSrc={news.image}
                title={news.title}
                onEdit={() => handleEdit(news)}
                onDelete={() => handleDelete(news.id)}
              />
            ))}
          </div>
        )}
      </div>

      {/* Modal for editing news */}
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
          resetFormFields();
        }}
        className="edit-news-modal"
        overlayClassName="edit-news-overlay"
      >
        <button
          onClick={() => setShowModal(false)}
          className="CloseProductModalBtn"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h2>Edit News</h2>
        <form onSubmit={handleUpdateNews}>
          <div className="form-group">
            <label htmlFor="image">Upload New Image (optional):</label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange}
              accept="image/*"
            />
          </div>
          <div className="form-group">
            <label htmlFor="modalTitle">Title:</label>
            <input
              type="text"
              id="modalTitle"
              value={modalTitle}
              onChange={(e) => setModalTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="modalCategory">Category:</label>
            <input
              type="text"
              id="modalCategory"
              value={modalCategory}
              onChange={(e) => setModalCategory(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="modalDetails">Details:</label>
            <ReactQuill
              value={modalDetails}
              onChange={setModalDetails}
              theme="snow"
              required
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block">
            Update
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default News;
