import React from "react";
import Header from "../component/header/Header";
import SliderImage from "./../component/slider/SliderImage";
import Divider from "../component/common/Divider";

const PrivacyPolicy = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />

      <SliderImage title="Privacy Policy" />

      <div className="privacyPolicyWrapper">
        <div className="container">
          <div className="ppDivOne">
            <p>
              This Privacy Policy outlines how Farmvaly Bangladesh collects,
              uses, stores, and discloses information from users ('User') of the
              https://farmvaly.com website ('Site'). It applies to the Site and
              all products and services provided by Farmvaly Bangladesh.
            </p>

            <p>
              We may collect personal identification information, such as name,
              email address, mailing address, phone number, and account number,
              when you interact with our Site through activities like visiting,
              registering, placing orders, submitting forms, or participating in
              our services or features. While you can visit our Site
              anonymously, providing personal information may be necessary to
              access certain Site-related activities.
            </p>

            <p>
              We may collect non-personal identification information, such as
              browser type, device information, operating system, and technical
              connection details, during your interactions with the Site. To
              enhance your experience, our Site may use cookies, which are
              stored on your device for record-keeping and tracking. You can
              adjust your browser settings to decline cookies or receive alerts
              when they are used, but this may impact certain Site
              functionality.
            </p>

            <p>
              We may collect and use your personal information, such as name,
              email address, mailing address, phone number, and account number,
              to operate the Site, display content accurately, improve customer
              service, and personalize your user experience. Additionally, we
              may use aggregated data to improve our products and services based
              on feedback. Personal information may also be used to process
              payments and fulfill orders, with sharing limited to necessary
              service delivery. We may send periodic emails related to orders
              and respond to inquiries or other requests.
            </p>
          </div>

          <Divider />

          <div className="ppDivTwo">
            <p>
              We prioritize your privacy and are committed to safeguarding your
              personal information. We employ rigorous data collection, storage,
              processing practices, and security measures to protect your
              usernames, passwords, transaction data, and other Site-stored
              information from unauthorized access, alteration, disclosure, or
              destruction.
            </p>

            <p>
              We prioritize user privacy and do not sell, trade, or rent
              personal identification information to third parties. We may share
              aggregated demographic data, which does not include personally
              identifiable information, with our business partners, trusted
              affiliates, and advertisers for specific purposes. We may also
              engage third-party service providers to assist with our business
              operations and site administration, such as sending newsletters or
              surveys. We will only share your personal information with these
              third parties for limited purposes if you have provided explicit
              consent.
            </p>

            <p>
              We offer an optional mailing list where users can subscribe to
              receive emails containing company news, updates, product or
              service information, etc. Please note that by using our services
              or products, you will automatically be subscribed to this mailing
              list. We may engage third-party service providers to assist with
              our business operations and site administration, including sending
              newsletters or surveys. We will only share your information with
              these third parties for limited purposes if you have provided
              explicit consent.
            </p>

            <p>
              We may be required to disclose personally identifiable information
              under certain circumstances, such as when mandated by law or when
              necessary to protect the safety of our users or the public. In
              these situations, we will strive to notify you beforehand,
              allowing you to seek legal protection if possible. Our website may
              contain links to external sites and services operated by third
              parties. We are not responsible for the content or practices of
              these linked websites, which may have their own privacy policies
              and customer service policies. Browsing and interacting with any
              linked website is subject to their own terms and conditions.
            </p>
          </div>
          <Divider />
          <div className="ppDivThree">
            <p>
              Farmvaly Bangladesh reserves the right to update this privacy
              policy at any time. We will notify users of any changes by posting
              an announcement on our website's homepage. We encourage users to
              regularly review this policy to stay informed about how we protect
              your personal information. You acknowledge that it is your
              responsibility to periodically review this policy and be aware of
              any modifications.
            </p>

            <p>
              Your use of this Site signifies your acceptance of this policy. If
              you disagree with this policy, please do not use our Site. Any
              continued use of the Site following changes to this policy will be
              deemed your acceptance of those changes.
            </p>

            <p>
              If you have any questions about this Privacy Policy, the practices
              of this site, or your interactions with this site, please contact
              us.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
