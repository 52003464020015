import React, { useState } from "react";
import logo from "../../assets/images/logo/logo.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const AdminLogin = ({ onLogin }) => {
  const [email, setId] = useState("");
  const [pass, setPassword] = useState("");

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_Local}/admin/login`,
        {
          email,
          pass,
        }
      );

      if (response.data.success) {
        const token = response.data.token;
        localStorage.setItem("adminToken", token);

        toast.success("Login successful");

        history.push("/admin/dashboard");
      } else {
        toast.error("Invalid email or password");
      }
    } catch (error) {
      toast.error("There was an error logging in:", error);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f4f4f4",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          width: "90%",
        }}
      >
        <img
          src={logo}
          alt="Company Logo"
          style={{
            width: "200px",
            height: "auto",
            marginBottom: "10px",
          }}
        />
        <div
          style={{
            fontSize: "1.2em",
            marginBottom: "10px",
            color: "#333",
            fontWeight: "bold",
          }}
        >
          Farmvaly: Cultivating Bangladesh's Agricultural Bounty for the World.
        </div>

        <div
          className="caution"
          style={{ color: "#FF0000", fontWeight: "bold", letterSpacing: "2px" }}
        >
          <i className="fas fa-exclamation-triangle"></i>
          Admin Panel
        </div>

        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "10px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                textAlign: "left",
                color: "#333",
                fontWeight: "bold",
              }}
            >
              Email:
            </label>
            <input
              type="text"
              value={email}
              onChange={(e) => setId(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
              required // Added required attribute for form validation
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "5px",
                textAlign: "left",
                color: "#333",
                fontWeight: "bold",
              }}
            >
              Password:
            </label>
            <input
              type="password"
              value={pass}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
              required
            />
          </div>

          {/* {error && (
            <p
              style={{
                color: "red",
                marginBottom: "10px",
                textAlign: "left",
              }}
            >
              {error}
            </p>
          )} */}

          <button
            type="submit"
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#28a745",
              color: "#fff",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
