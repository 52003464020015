import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import logom from "../../assets/images/logo/logo.png";
import { withTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa";

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.state = { isOpen: false };
  }

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);
    this.handleSubmenuClicks();
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const header = document.querySelector(".header-area");
    if (window.scrollY > 20) {
      header.classList.remove("sticky");
      header.classList.add("stickybg");
    } else {
      header.classList.remove("stickybg");
      header.classList.add("sticky");
    }
  };

  handleSubmenuClicks() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  handleMenuClick() {
    this.CLoseMenuTrigger();
  }

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
    this.setState({ isOpen: false });
  };

  showLanguageMenu = () => {
    this.setState({ isOpen: true });
  };

  hideLanguageMenu = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { color, headerPosition, textColor, t } = this.props;
    const currentPath = this.props.location.pathname;
    const { isOpen } = this.state;

    const linkStyle = {
      color: textColor || "black",
    };

    return (
      <header
        className={`header-area formobile-menu ${headerPosition} ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 col-6">
                <div className="header-left row">
                  <div className="logo">
                    <a href="/">
                      <img src={logom} alt="Farmvaly" />
                    </a>
                  </div>
                  <Link to="/bangladesh" className="btn btn-bangladesh">
                    Bangladesh
                  </Link>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-6">
                <div className="header-right justify-content-end">
                  <nav className="mainmenunav d-lg-block">
                    <ul className="mainmenu">
                      <li
                        className={`${
                          currentPath === "/bangladesh" ? "active" : ""
                        } logo-mobile-menu`}
                      >
                        <Link
                          to="/bangladesh"
                          onClick={this.handleMenuClick}
                          style={linkStyle}
                        >
                          <img src={logom} alt="" srcset="" />
                        </Link>
                      </li>
                      <li
                        className={`${
                          currentPath === "/bangladesh" ? "active" : ""
                        } btn-mobile-bangladesh`}
                      >
                        <Link
                          to="/bangladesh"
                          onClick={this.handleMenuClick}
                          style={linkStyle}
                        >
                          Bangladesh
                        </Link>
                      </li>
                      <li className={currentPath === "/" ? "active" : ""}>
                        <Link
                          to="/"
                          onClick={this.handleMenuClick}
                          style={linkStyle}
                        >
                          Home
                        </Link>
                      </li>
                      <li
                        className={currentPath === "/about-us" ? "active" : ""}
                      >
                        <Link
                          to="/about-us"
                          onClick={this.handleMenuClick}
                          style={linkStyle}
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="has-droupdown">
                        <Link to="" style={linkStyle}>
                          Our Business
                        </Link>
                        <ul className="submenu">
                          {/* <li>
                            <Link to="/shop">Shop</Link>
                          </li> */}
                          <li>
                            <Link to="/our-business/export">Export</Link>
                          </li>
                          <li>
                            <Link to="/our-business/local-trading">
                              Local Trading
                            </Link>
                          </li>
                          <li>
                            <Link to="/our-business/lifestyle-brand">
                              Lifestyle Brand
                            </Link>
                          </li>
                          <li>
                            <Link to="/our-business/online-business">
                              Online Shop
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/our-business/import-distribution">Import & Distribution</Link>
                          </li> */}
                          {/* <li>
                            <Link to="/our-business/our-farmhouse">
                              Our Farmhouse
                            </Link>
                          </li> */}
                          
                        </ul>
                      </li>
                      <li className="has-droupdown">
                        <Link to="" style={linkStyle}>
                          Our Product
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/products">Products</Link>
                          </li>
                        </ul>
                      </li>
                      <li
                        className={currentPath === "/article" ? "active" : ""}
                      >
                        <Link
                          to="/article"
                          onClick={this.handleMenuClick}
                          style={linkStyle}
                        >
                          Article
                        </Link>
                      </li>
                      <li
                        className={currentPath === "/contact" ? "active" : ""}
                      >
                        <Link
                          to="/contact"
                          onClick={this.handleMenuClick}
                          style={linkStyle}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </nav>

                  {/* Start Hamburger Menu */}
                  <div className="humberger-menu d-block d-lg-none pl--20">
                    <span
                      onClick={this.menuTrigger}
                      className="menutrigger text-white"
                    >
                      <FiMenu />
                    </span>
                  </div>
                  {/* End Hamburger Menu */}
                  <div className="close-menu d-block d-lg-none">
                    <span
                      onClick={this.CLoseMenuTrigger}
                      className="closeTrigger"
                    >
                      <FiX />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(withTranslation()(Header));
