import React from "react";
import exportimg from "../assets/images/export.jpg";
import imageback from "../assets/images/imageback.jpg";
import { motion } from "framer-motion";
import checkicon from "../assets/images/icons/check.png";
import containerIMG from "../assets/images/containersImage.jpg";
import farmer from "../assets/images/farmerimg.jpg";
import prdouctRow from "../assets/images/productsRow.jpg";
import Header from "../component/header/Header";
import { LeafTitle } from "./../component/common/LeafTitle";

const whypartner = [
  {
    title: "Quality You Can Trust",
    description:
      "At Farmvaly, we prioritize quality in everything we do. Our products undergo strict quality checks to ensure they meet the highest standards for hygiene, taste, and nutrition. We believe in being transparent and providing products that exceed your expectations.",
    bgImg: prdouctRow,
  },
  {
    title: "Partnering with Local Farmers for a Sustainable Future",
    description:
      "We work closely with over 2,000 local farmers who are committed to sustainable and ethical farming practices. By focusing on environmental protection and supporting local communities, we create a responsible and resilient supply chain. Every product you buy helps support these farmers and their sustainable practices.",
    bgImg: farmer,
  },
  {
    title: "Global Reach with Local Roots",
    description:
      "We understand the global market and offer customized solutions to meet your specific needs. From packaging to shipping, our global logistics network ensures your products are delivered on time. As your reliable partner, we simplify the export process so your business can succeed.",
    bgImg: containerIMG,
  },
  {
    title: "Traceability and Compliance",
    description:
      "Our products are fully traceable from the farm to your table. We strictly follow international regulations and certifications to ensure every shipment is documented and meets the highest safety standards.",
  },
  {
    title: "Tailored Solutions for Your Market",
    description:
      "We understand that every market is different. That's why we offer flexible options, including customized packaging, product specifications, and shipping schedules. At Farmvaly Bangladesh, we work with you to deliver products that perfectly meet your needs.",
  },
  {
    title: "Preserving Bangladesh's Agricultural Heritage",
    description:
      "At Farmvaly Bangladesh, we're more than just exporters. We're dedicated to preserving Bangladesh's rich agricultural heritage and sharing it with the world. Our mission is to build trust, reliability, and long-lasting relationships with our partners based on mutual respect, transparency, and shared success.",
  },
];

const Export = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />
      <div className="container">
        <div className="market-container">
          <div className="image-container">
            <img src={exportimg} alt="Agriculture Image 1" className="image1" />
            <img src={imageback} alt="Agriculture Image 2" className="image2" />
          </div>
          <div className="business-content-container">
            <h1>Export</h1>
            <p>
              Farmvaly Bangladesh is a leading exporter of high-quality
              agricultural products from Bangladesh. We connect local farmers
              and producers with customers around the world. With years of
              experience and a commitment to excellence, we offer a wide variety
              of premium products that meet international standards. Our
              carefully selected products include:
            </p>
            <ul className="business-feature-list">
              <li>
                <img src={checkicon} alt="" className="checkIconExport" />
                <span style={{ fontWeight: "bold", display: "inline" }}>
                  Meat Products:
                </span>{" "}
                Exporting quality-assured Beef Omasum and Beef Pizzle/Bully
                Sticks.
              </li>
              <br />
              <li>
                <img src={checkicon} alt="" className="checkIconExport" />
                <span style={{ fontWeight: "bold", display: "inline" }}>
                  Oilseeds & Oils:
                </span>{" "}
                Featuring both hulled and unhulled sesame seeds, sesame oil, and
                rapeseed oil.
              </li>
              <br />
              <li>
                <img src={checkicon} alt="" className="checkIconExport" />
                <span style={{ fontWeight: "bold", display: "inline" }}>
                  Pulses & Spices:
                </span>{" "}
                Supplying green mung beans and dry red chilies with an emphasis
                on freshness and authenticity.
              </li>
              <br />
              <li>
                <img src={checkicon} alt="" className="checkIconExport" />
                <span style={{ fontWeight: "bold", display: "inline" }}>
                  Specialty Products:
                </span>{" "}
                Offering pumpkin seeds, fish maw, dry fish, and sesame crude
                oil, harvested and processed with care.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <LeafTitle title={"Why Partner with Farmvaly Bangladesh?"} />

      <div className="exportcards-area ptb--60 bg_color--5">
        <div className="container">
          <div className="row exportcards-wrapper">
            {whypartner.map((val, i) => (
              <motion.div
                initial={{ y: 90, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0, duration: 0.5 }}
                className="exportCARD col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                key={i}
              >
                <div className="card-inner">
                  <div
                    className="card-front"
                    style={{
                      backgroundImage: val.bgImg ? `url(${val.bgImg})` : "none",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backfaceVisibility: "hidden",
                    }}
                  >
                    <h2
                      className={`title ${
                        val.bgImg ? "text-white" : "text-black"
                      }`}
                    >
                      {val.title}
                    </h2>
                  </div>
                  <div className="card-back">
                    <div className="content">
                      <h3>{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="exportBottomText">
          <p>
            Whether you need high-quality agricultural products or a reliable
            partner, Farmvaly Bangladesh is here for you. Explore our wide range
            of products and see how our expertise and commitment to quality can
            benefit your business.
          </p>
        </div>
      </div>

      <div className="call-to-action-wrapper call-to-action text-white-wrapper ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h2>Contact Us today</h2>
                <a className="rn-button-style--2" href="/contact">
                  <span>Contact Us</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Export;
