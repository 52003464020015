import React, { Component } from "react";
import productsdata from "../data/productsdata";
import ProductCard from "../component/product/ProductCard";
import Modal from "react-modal";
import ProductDetails from "../component/product/ProductDetails";
import ContactForm from "../elements/contact/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import "../assets/css/products.css";
import { LeafTitle } from "./../component/common/LeafTitle";
import Header from "../component/header/Header";
import SliderImage from "../component/slider/SliderImage";
import ourProductSlide from "../assets/images/ourProductsSlide.jpg";

Modal.setAppElement("#root");

class Products extends Component {
  state = {
    isProductModalOpen: false,
    isQuoteModalOpen: false,
    selectedProduct: null,
    quoteProductName: "",
  };

  openProductModal = (product) => {
    this.setState({ isProductModalOpen: true, selectedProduct: product });
  };

  closeProductModal = () => {
    this.setState({ isProductModalOpen: false, selectedProduct: null });
  };

  openQuoteModal = (productName) => {
    this.setState({ isQuoteModalOpen: true, quoteProductName: productName });
  };

  closeQuoteModal = () => {
    this.setState({ isQuoteModalOpen: false });
  };

  render() {
    const { isProductModalOpen, isQuoteModalOpen, selectedProduct } =
      this.state;

    return (
      <React.Fragment>
        <Header
          headerPosition="header--fixed sticky logoresize"
          logo="all-dark"
          color="color-black"
        />

        <SliderImage title={"Our Products"} />

        <motion.div
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          className="products-wrapper"
        >
          <LeafTitle title={"Special Products"} />
          <div className="products-grid">
            {productsdata
              .filter((product) => product.category === "special") 
              .map((value, index) => (
                <ProductCard
                  key={index}
                  product={value}
                  onClick={() => this.openProductModal(value)}
                />
              ))}
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          className="products-wrapper"
        >
          <LeafTitle title={"Vegetables"} />
          <div className="products-grid">
            {productsdata.filter((product) => product.category === "vegetable").map((value, index) => (
              <ProductCard
                key={index}
                product={value}
                onClick={() => this.openProductModal(value)}
              />
            ))}
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          className="products-wrapper"
        >
          <LeafTitle title={"Seeds"} />
          <div className="products-grid">
            {productsdata.filter((product) => product.category === "seed").map((value, index) => (
              <ProductCard
                key={index}
                product={value}
                onClick={() => this.openProductModal(value)}
              />
            ))}
          </div>
        </motion.div>
        <motion.div
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          className="products-wrapper"
        >
          <LeafTitle title={"Spices"} />
          <div className="products-grid">
            {productsdata.filter((product) => product.category === "spices").map((value, index) => (
              <ProductCard
                key={index}
                product={value}
                onClick={() => this.openProductModal(value)}
              />
            ))}
          </div>
        </motion.div>
        <motion.div
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          className="products-wrapper"
        >
          <LeafTitle title={"Fruits"} />
          <div className="products-grid">
            {productsdata.filter((product) => product.category === "fruits").map((value, index) => (
              <ProductCard
                key={index}
                product={value}
                onClick={() => this.openProductModal(value)}
              />
            ))}
          </div>
        </motion.div>
        <motion.div
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 1 }}
          className="products-wrapper"
        >
          <LeafTitle title={"Other Product"} />
          <div className="products-grid">
            {productsdata.filter((product) => product.category === "other").map((value, index) => (
              <ProductCard
                key={index}
                product={value}
                onClick={() => this.openProductModal(value)}
              />
            ))}
          </div>
        </motion.div>

        {/* Product Details Modal */}
        <Modal
          isOpen={isProductModalOpen}
          onRequestClose={this.closeProductModal}
          className="productsDetailsModal"
          overlayClassName="productsDetailscustomOverlay"
        >
          <button
            onClick={this.closeProductModal}
            className="CloseProductModalBtn"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          {selectedProduct && (
            <ProductDetails
              product={selectedProduct}
              openQuoteModal={this.openQuoteModal}
            />
          )}
        </Modal>

        {/* Quote Modal */}
        <Modal
          isOpen={isQuoteModalOpen}
          onRequestClose={this.closeQuoteModal}
          className="quoteModalModal"
          overlayClassName="quoteModalcustomOverlay"
        >
          <button onClick={this.closeQuoteModal} className="CloseQuoteModalBtn">
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h2 style={{ textAlign: "center" }}>Get a Quote</h2>
          <ContactForm productName={this.state.quoteProductName} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default Products;
